exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/aboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-terms-and-condition-js": () => import("./../../../src/pages/termsAndCondition.js" /* webpackChunkName: "component---src-pages-terms-and-condition-js" */),
  "component---src-templates-blog-template-blog-page-js": () => import("./../../../src/templates/BlogTemplate/BlogPage.js" /* webpackChunkName: "component---src-templates-blog-template-blog-page-js" */)
}

